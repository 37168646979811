<template>
    <el-dialog :title="title" @close="dialogClose" width="800px" :visible.sync="dialogShow" :close-on-click-modal="false" append-to-body>
        <el-form inline label-width="70px" v-if="title == '选择地址'||'选择情景位置'">
          <el-form-item :label="selectLabel">
            <el-select v-model="checkList" @change="checkListChange" size="mini" style="width: 120px">
              <el-option label="房间列表" value="房间"></el-option>
              <el-option label="公区列表" value="公区"></el-option>
              <el-option label="办公室列表" value="办公室"></el-option>
              <el-option label="库房列表" value="库房"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      <el-form inline label-width="70px" v-if="checkList=='房间'" style="display:inine-block">
        <el-form-item label="城区">
          <el-select v-model="params.hsAddDistrict" @change="getRoomList(1)" clearable size="mini" style="width: 120px">
            <el-option v-for="(item,index) in cityList" :key="index" :label="item.hdDistrict" :value="item.hdDistrict"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="房间名称">
          <el-input v-model="params.hsAddCommunity" @input="searchOnkeyup(getRoomList)" clearable size="mini" style="width: 120px"></el-input>
        </el-form-item>
        <el-form-item label="门牌搜索">
          <el-input v-model="params.hsAddDoorplateno" @input="searchOnkeyup(getRoomList)" clearable size="mini" style="width: 120px"></el-input>
        </el-form-item> 
      </el-form>
      <el-form inline label-width="70px" v-else-if="checkList=='公区' || checkList=='办公室' || checkList=='库房'">
        <el-form-item label="项目名称">
          <el-input v-model="params.hsAddCommunity" @input="searchOnkeyup(getOtherList)" clearable size="mini" style="width: 120px"></el-input>
        </el-form-item> 
        <el-form-item label="项目备注">
          <el-input v-model="params.hsHouseNote" @input="searchOnkeyup(getOtherList)" clearable size="mini" style="width: 120px"></el-input>
        </el-form-item> 
      </el-form>
      <el-form inline label-width="70px" v-else-if="checkList=='供应商'">
        <el-form-item label="商家名称">
          <el-input v-model="params.keyAdministrator" @input="searchOnkeyup(getSupplierList)" clearable size="mini" style="width: 120px"></el-input>
        </el-form-item> 
        <el-form-item label="备注描述">
          <el-input v-model="params.addDoorplateno" @input="searchOnkeyup(getSupplierList)" clearable size="mini" style="width: 120px"></el-input>
        </el-form-item> 
        <el-form-item label="联系人">
          <el-input v-model="params.keyNumber" @input="searchOnkeyup(getSupplierList)" clearable size="mini" style="width: 120px"></el-input>
        </el-form-item> 
        <el-form-item label="联系电话">
          <el-input v-model="params.houseEntrust4rent" @input="searchOnkeyup(getSupplierList)" clearable size="mini" style="width: 120px"></el-input>
        </el-form-item> 
      </el-form>
      <el-table :data="dataList" v-if="checkList=='房间'" @row-dblclick="RoomDblclick" v-loading="loading" highlight-current-row style="width: 100%" height="288px" size="mini">
        <el-table-column prop="hsAddDistrict" label="城区"></el-table-column>
        <el-table-column prop="hsAddCommunity" label="门店"></el-table-column>
        <el-table-column prop="hsRoomType" label="房型"></el-table-column>
        <el-table-column prop="hsAddFloor" label="楼层"></el-table-column>
        <el-table-column prop="hsAddDoorplateno" label="门牌"></el-table-column>
      </el-table>
      <el-table :data="dataList" v-else-if="checkList=='公区' || checkList=='办公室' || checkList=='库房'" @row-dblclick="otherDblclick" v-loading="loading" highlight-current-row style="width: 100%" height="288px" size="mini">
        <el-table-column prop="hsAddCity" label="分类"></el-table-column>
        <el-table-column prop="hsAddCommunity" label="名称"></el-table-column>
        <el-table-column prop="hsHouseNote" label="备注描述"></el-table-column>
        <el-table-column prop="registrantName" label="联系人"></el-table-column>
        <el-table-column prop="registrantTelephone" label="联系电话"></el-table-column>
      </el-table>
      <el-table :data="dataList" v-else-if="checkList=='供应商'" @row-dblclick="supplierDblclick" v-loading="loading" highlight-current-row style="width: 100%" height="288px" size="mini">
        <el-table-column prop="keyAdministrator" label="名称"></el-table-column>
        <el-table-column prop="addDoorplateno" label="备注描述"></el-table-column>
        <el-table-column prop="keyNumber" label="联系人"></el-table-column>
        <el-table-column prop="houseEntrust4rent" label="联系电话"></el-table-column>
      </el-table>
      <el-pagination 
        layout="prev,pager,next,total"
        :page-size="limit"
        :current-page="cursor"
        :page-count="pages"
        :total="total"
        align="center"
        background
        @current-change="currentChange"></el-pagination>
    </el-dialog>
</template>
<script>
  import { mapState } from 'vuex';
  export default {
    props: {
      isDialogShow: {
        type: Boolean,
        default: false,
      },
      isTitle: {
        type: String,
        default: '选择房间',
      },
    },
    data(){
      return {
        dialogShow: this.isDialogShow,
        title: this.isTitle,
        checkList: '房间',
        dataList:[],
        limit: 5,   //每页个数
        cursor: 1,  //当前页面
        pages: 0,   //总页数
        total: 0,   //总条数
        params:{
          hsAddDistrict: '',
          hsAddCommunity: '',
          hsHouseNote: '',
          keyAdministrator: '',//商家名称
          addDoorplateno: '',//备注
          keyNumber: '',//联系人
          houseEntrust4rent: '',//电话
        }
      }
    },
    computed: {
      ...mapState(['cityList']),
      selectLabel(){
        if(this.title != '选择场景位置'){
          return '选择列表'
        }else{
          return '位置类型'
        }
      }
    },
    created(){
      if (this.title == '选择地址') {
        this.checkList = '房间'
        this.getRoomList()
      } else if (this.title == '选择场景位置') {
        this.checkList = '房间'
        this.getRoomList()
      }else if (this.title == '选择房间') {
        this.checkList = '房间'
        this.getRoomList()
      } else if (this.title == '选择公区') {
        this.checkList = '公区'
        this.getOtherList()
      } else if (this.title == '选择办公室') {
        this.checkList = '办公室'
        this.getOtherList()
      } else if (this.title == '选择库房') {
        this.checkList = '库房'
        this.getOtherList()
      } else if (this.title == '选择供应商') {
        this.checkList = '供应商'
        this.getSupplierList()
      } else if (this.title == '选择所属房间'){
        this.checkList = '房间'
        this.getRoomList()
      }
    },
    watch: {
      dialogShow(n, o) {
        this.$emit('close');
      },
    },
    methods: {
      checkListChange() {
        this.dataList = []
        this.params = {
          hsAddDistrict: '',
          hsAddCommunity: '',
          hsHouseNote: '',
          keyAdministrator: '',//商家名称
          addDoorplateno: '',//备注
          keyNumber: '',//联系人
          houseEntrust4rent: '',//电话
        }
        if (this.checkList == '房间') {
          this.getRoomList(1)
        } else if (this.checkList == '公区' || this.checkList == '办公室' || this.checkList == '库房') {
          this.getOtherList(1)
        } else if (this.checkList == '供应商') {
          this.getSupplierList(1)
        }
      },
      getRoomList(t) {//分页查询房间
        if(t == 1){
          this.cursor = 1
        }
        this.loading = true
        this.$axios({
          method: 'get',
          url: '/common1/house4store',
          params: {
            hsAddDistrict: this.params.hsAddDistrict,
            hsAddCommunity: this.params.hsAddCommunity,
            hsAddDoorplateno:this.params.hsAddDoorplateno,
            hsLeaseState: this.title == '选择场景位置' | this.title == '选择所属房间' ? '' : '短租房',
            limit: this.limit,
            cursor: this.cursor,
          }
        }).then(res => {
          this.dataList = res.data.result.records;
          this.total = res.data.result.total;
          this.loading = false
          console.log(this.dataList);
        }).catch(err => {
          this.loading = false
          this.isError(err,this)
        });

      },
      getOtherList(t) {//分页查询公区、办公室、库房
        if(t == 1){
          this.cursor = 1
        }
        this.loading = true
        this.$axios({
          method: 'get',
          url: '/common1/house4store',
          params: {
            hsAddCommunity: this.params.hsAddCommunity,
            hsHouseNote: this.params.hsHouseNote,
            hsAddCity: this.checkList,
            limit: this.limit,
            cursor: this.cursor,
          }
        }).then(res => {
          res.data.result.records.forEach(i => {
            i.hsHouseNote = i.hsHouseNote ? i.hsHouseNote : ''
          })
          this.dataList = res.data.result.records;
          this.total = res.data.result.total;
          this.loading = false
          console.log(this.dataList);
        }).catch(err => {
          this.loading = false
          this.isError(err,this)
        });
       
      },
      getSupplierList(t) {//分页查询供应商
        if(t == 1){
          this.cursor = 1
        }
        this.loading = true
        this.$axios({
          method: 'get',
          url: '/common1/housePapers/projects',
          params: {
            keyAdministrator: this.params.keyAdministrator,//商家名称
            addDoorplateno: this.params.addDoorplateno,//备注
            keyNumber: this.params.keyNumber,//联系人
            houseEntrust4rent: this.params.houseEntrust4rent,//电话
            houseEntrust4sell: '正常',//状态
            virtualType: 5,
            limit: this.limit,
            cursor: this.cursor,
          }
        }).then(res => {
          this.dataList = res.data.result.records;
          this.total = res.data.result.total;
          this.loading = false
          console.log(this.dataList);
        }).catch(err => {
          this.loading = false
          this.isError(err,this)
        });
      },
      RoomDblclick(row) {//双击房间选择
        row.hsAddCommunity = row.hsAddCommunity ? row.hsAddCommunity : ''
        row.hsRoomType = row.hsRoomType ? row.hsRoomType : ''
        row.hsAddDoorplateno = row.hsAddDoorplateno ? row.hsAddDoorplateno : ''
        row.address = `${row.hsAddCommunity}\u3000${row.hsRoomType}\u3000${row.hsAddDoorplateno}`
        row.checkList = this.checkList
        this.$emit('close',row)
        console.log(row)
      },
      otherDblclick(row) {//双击公区、办公室、库房选择
        row.hsAddCommunity = row.hsAddCommunity ? row.hsAddCommunity : ''
        row.address = row.hsAddCommunity
        row.checkList = this.checkList
        this.$emit('close',row)
      },
      supplierDblclick(row) {//双击供应商选择
        row.keyAdministrator = row.keyAdministrator ? row.keyAdministrator : ''
        row.checkList = this.checkList
        this.$emit('close',row)
      },
      currentChange(val) {//分页
        this.cursor = val;
        if (this.checkList=='房间') {
          this.getRoomList();
        } else if (this.checkList=='公区' || this.checkList=='办公室' || this.checkList=='库房') {
          this.getOtherList();
        } else if (this.checkList=='供应商') {
          this.getSupplierList();
        }
      },
      dialogClose() {//房源弹窗关闭
        this.title = '选择房间';
        this.params = {
          hsAddDistrict: '',
          hsAddCommunity: '',
          hsHouseNote: '',
          keyAdministrator: '',//商家名称
          addDoorplateno: '',//备注
          keyNumber: '',//联系人
          houseEntrust4rent: '',//电话
        }
      },
    }
  }
</script>
